.form-page {
  height: 100%;
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;
}

.form-page > div.f-pm-focus {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(35, 35, 35, 0.6);
  z-index: 0;
}

.form-page > img.f-pm-logo {
  position: fixed;
  left: 15vw;
  top: 25vh;
  width: 32.292vw;
  height: auto;
  z-index: 1;
}

.form-page > h1.f-pm-title {
  position: fixed;
  left: 15vw;
  top: 43vh;
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 2.5vw;
  line-height: 135%;
  padding: 0;
  margin: 2.96vh 0 0 0;
  color: #ffffff;
  width: 32.292vw;
  z-index: 1;
}

.form-page > p.f-pm-desc {
  position: fixed;
  left: 15vw;
  top: 46vh;
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 1.2vw;
  line-height: 133%;
  letter-spacing: 0.005em;
  margin: 1.48vh 0 0 0;
  padding: 0;
  color: #ffffff;
  width: 32.292vw;
  z-index: 1;
  white-space: pre-wrap;
}

.form-page > div.f-pm-buttons {
  position: fixed;
  left: 15vw;
  top: 70vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.form-page > div.f-pm-buttons > div.f-pm-button {
  border: 1px solid #ffffff;
  box-sizing: border-box;
  border-radius: 50px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  /*width: 10.938vw;*/
  padding: 10px 16px 9px 16px;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
  z-index: 1;
  margin-right: 28px;
}

.form-page > div.f-pm-buttons > div.f-pm-button:hover {
  background-color: #ffffff;
}

.form-page > div.f-pm-buttons > div.f-pm-button > p {
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 0.833vw;
  line-height: 100%;
  text-align: center;
  letter-spacing: 0.0125em;
  text-transform: uppercase;
  color: #ffffff;
  margin: 0;
  padding: 0;
  transition: color 0.3s ease-in-out;
}

.form-page > div.f-pm-buttons > div.f-pm-button:hover > p {
  color: #101010;
}

.form-page > div.f-pm-buttons > div.f-pm-button > svg {
  width: 1.25vw;
  height: auto;
  margin-left: 8px;
}

.form-page > div.f-pm-buttons > div.f-pm-button > svg > * {
  fill: #ffffff;
  transition: fill 0.3s ease-in-out;
}

.form-page > div.f-pm-buttons > div.f-pm-button:hover > svg > * {
  fill: #101010;
}

.form-page > img.f-pm-portugal {
  position: fixed;
  left: 15vw;
  bottom: 5.5vh;
  z-index: 1;
  width: 35vw;
  height: auto;
}

.form-page > div.f-pm-powered {
  position: fixed;
  bottom: 4.63vh;
  right: 8.33vw;
  z-index: 1;
}

.form-page > div.f-pm-powered > p {
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 0.729vw;
  line-height: 135%;
  text-transform: lowercase;
  color: #ffffff;
  margin: 0 0 0.74vh 0;
  padding: 0;
}

.form-page > div.f-pm-powered > svg {
  width: 8.594vw;
  height: auto;
}

.f-title {
  position: fixed;
  right: 15vw;
  top: 25vh;
  width: 32.292vw;
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 34px;
  line-height: 36px;
  margin: 0;
  padding: 0;
  color: #ffffff;
}

div.f-info {
  position: fixed;
  right: 15vw;
  top: 33vh;
  width: 32.292vw;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

div.f-info > input {
  width: 15.5vw;
  background: rgba(255, 255, 255, 0.7);
  border-radius: 8px;
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 1vw;
  line-height: 100%;
  letter-spacing: 0.004em;
  color: #363636;
  padding: 1vw;
  border: none;
  height: auto;
}

div.f-info > input:focus {
  outline: none;
  background: rgba(255, 255, 255, 0.9);
}

form > textarea.f-textarea {
  position: fixed;
  right: 15vw;
  top: 50vh;
  width: 32.292vw;
  height: 17.5vh;
  resize: none;
  background: rgba(255, 255, 255, 0.7);
  border-radius: 8px;
  padding: 1vw;
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 1vw;
  line-height: 100%;
  letter-spacing: 0.004em;
  color: #363636;
}

form > textarea.f-textarea:focus {
  outline: none;
  background: rgba(255, 255, 255, 0.9);
}

form > input.f-send {
  position: fixed;
  right: 15vw;
  top: 70vh;
  border: 1px solid #ffffff;
  box-sizing: border-box;
  border-radius: 50px;
  background: transparent;
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 0.833vw;
  line-height: 1.25vw;
  text-align: center;
  letter-spacing: 0.0125em;
  text-transform: uppercase;
  color: #ffffff;
  padding: 10px 16px 9px 16px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

form > input.f-send:hover {
  cursor: pointer;
  color: #101010;
  background-color: #ffffff;
}

div.f-loc {
  position: fixed;
  right: 15vw;
  top: 41.5vh;
  width: 32.292vw;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

div.f-loc > input {
  width: 100%;
  background: rgba(255, 255, 255, 0.7);
  border-radius: 8px;
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 1vw;
  line-height: 100%;
  letter-spacing: 0.004em;
  color: #363636;
  padding: 1vw;
  border: none;
}

span.f-feedback {
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translate(-50%);
  width: 200px;
  background: #949494;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Open Sans;
  font-size: 16px;
  font-style: normal;
  line-height: 175%;
  opacity: 0;
  pointer-events: none;
  z-index: 1;
  transition: all 0.3s ease-in-out;
}

span.f-feedback.active {
  opacity: 1;
  bottom: 5vh;
}

div.contact-info {
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 1vw;
  line-height: 100%;
  position: fixed;
  top: 70vh;
  left: 52.7vw;
  pointer-events: none;
}

div.email-info {
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 1vw;
  line-height: 100%;
  position: fixed;
  top: 73vh;
  left: 52.7vw;
  text-transform: lowercase;
  cursor: pointer;
}

.mobile-form-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 10vw;
  overflow: auto;
  height: 100vh;
}

.mobile-form-page > .mobile-fp-background {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: auto;
  height: 120%;
  z-index: 0;
}

@media all and (orientation: landscape) {
  .mobile-form-page > .mobile-fp-background {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 120%;
    height: auto;
    z-index: 0;
  }
}

.mobile-form-page > .mobile-fp-focus {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(35, 35, 35, 0.6);
  z-index: 1;
}

.mobile-form-page * {
  flex: 1;
  z-index: 2;
}

.mobile-form-page > .mobile-fp-logo {
  width: 250px;
  height: auto;
}

.mobile-form-page > .mobile-fp-description {
  width: 100%;
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 133%;
  letter-spacing: 0.005em;
  margin: 20px 0 0 0;
  padding: 0;
  padding-bottom: 50px;
  color: #ffffff;
}

.mobile-form-page > .mobile-fp-thanks {
  width: 100%;
  text-align: start;
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 115%;
  margin: 0 0 12px 0;
  padding: 0;
  color: #ffffff;
}

.mobile-form-page > form {
  max-width: 100%;
  width: 100%;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.mobile-form-page > form > .mobile-fp-input {
  flex: 1;
  width: 100%;
  background: rgba(255, 255, 255, 0.7);
  border-radius: 8px;
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 100%;
  letter-spacing: 0.004em;
  color: #363636;
  padding: 12px;
  border: none;
  height: auto;
  margin-bottom: 12px;
}

.mobile-form-page > form > .mobile-fp-textarea {
  flex: 125px;
  width: 100%;
  background: rgba(255, 255, 255, 0.7);
  border-radius: 8px;
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 100%;
  letter-spacing: 0.004em;
  color: #363636;
  padding: 12px;
  border: none;
  height: 50vh;
  margin-bottom: 12px;
}

.mobile-form-page > form > .mobile-fp-send-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding-bottom: 50px;
}

.mobile-form-page > form > .mobile-fp-send-container > .mobile-fp-contact {
  white-space: pre-wrap;
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 150%;
  letter-spacing: 0.004em;
  color: #ffffff;
}

.mobile-form-page > form > .mobile-fp-send-container > .mobile-fp-send {
  height: 45px;
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 100%;
  letter-spacing: 0.004em;
  color: #ffffff;
  border: 1px solid #ffffff;
  box-sizing: border-box;
  border-radius: 50px;
  background: transparent;
  text-transform: uppercase;
}

.mobile-form-page > .mobile-fp-button {
  flex: none;
  width: 100%;
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 100%;
  letter-spacing: 0.004em;
  color: #ffffff;
  border: 1px solid #ffffff;
  box-sizing: border-box;
  border-radius: 50px;
  background: transparent;
  margin-bottom: 20px;
  text-transform: uppercase;
  height: 45px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.mobile-form-page > .mobile-fp-powered > .mobile-fp-portugal {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 135px;
}

.mobile-form-page > .mobile-fp-powered {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
  padding-top: 50px;
}

.mobile-form-page > .mobile-fp-powered > p {
  flex: none;
  width: 125px;
  text-align: left;
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  line-height: 100%;
  text-transform: lowercase;
  color: #ffffff;
  margin: 0 0 8px 0;
  padding: 0;
}

.mobile-form-page > .mobile-fp-powered > svg {
  flex: none;
  width: 125px;
}
