@import url('https://fonts.googleapis.com/css2?family=Merriweather+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Mukta+Vaani:wght@200;300;400;500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,wght@0,200;0,300;0,400;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');

body {
  background: #ffffff;
}

* {
  scrollbar-width: thin;
  scrollbar-color: rgb(var(--main-color)) transparent;
  scroll-behavior: smooth;
  line-height: 1.6;
}

::-webkit-scrollbar {
  width: 0.5vw; /* width of the entire scrollbar */
  height: 0.5vw;
}
::-webkit-scrollbar-track {
  background: transparent; /* color of the tracking area */
}
::-webkit-scrollbar-thumb {
  background-color: rgb(var(--main-color)); /* color of the scroll thumb */
  border-radius: 70px; /* roundness of the scroll thumb */
}

div {
  position: relative;
}

.landing-page {
  height: 100%;
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.landing-page.linea {
  height: 100%;
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 40% 90%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.landing-page.linea > .fachada {
  width: 44%;
  height: 86%;
}

.landing-page.linea > .fachada > * {
  position: absolute;
  top: 0;
  left: 0;
  width: auto;
  height: 100%;
}

.landing-page.linea > .fachada > svg > g {
  opacity: 0;
  transition: opacity 0.3s ease;
  cursor: pointer;
  pointer-events: all;
}

.landing-page.linea > .fachada > svg > g:hover {
  opacity: 1;
}

.landing-page.linea > .logo {
  position: absolute;
  top: 7%;
  left: 10.5vw;
  width: 5vw;
  height: auto;
}

.landing-page > .center-container > .info > .buttons-container > .start:hover {
  background-color: #5cb85c;
}

.landing-page > .logo {
  position: absolute;
  left: 50%;
  bottom: 3%;
  transform: translateX(-50%);
  width: 15%;
  height: auto;
}

.landing-page > .sponsor > img {
  flex: 1;
  width: 100%;
}

#rotatePage {
  width: 100vw;
  height: 100vh;
  background: rgba(var(--main-color), 1);
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 8vh 0 14vh 0;
  z-index: 999;
}

#rotatePage > .rotateDevice {
  flex: 6;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#rotatePage > .rotateDevice > p {
  font-family: 'Merriweather Sans', sans-serif;
  font-size: 5vw;
  line-height: 1.6;
  text-align: center;
  width: 75%;
}

#rotatePage > .rotateDevice > img {
  width: 30vw;
}

#rotatePage > h1 {
  flex: 1;
  font-family: 'Merriweather Sans', sans-serif;
  font-size: 6vw;
  line-height: 120%;
  text-align: center;
  margin: 0;
}

#rotatePage > .footer {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#rotatePage > .footer > p {
  font-family: 'Merriweather Sans', sans-serif;
  font-size: 4vw;
  line-height: 120%;
  text-align: center;
  margin: 0 0 3vw 0;
}

#rotatePage > .footer > img {
  width: 40vw;
}
