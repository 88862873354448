.hotspot-label > .content {
  background: rgba(255, 255, 255, 0.5);
  box-shadow: 0px 3px 56px rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(15px);
  border-radius: 5px;
  padding: 16px;
}

.hotspot-label > .content > .triangle {
  position: absolute;
  bottom: 0;
  transform: translate(0, 100%);
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 15px solid rgba(255, 255, 255, 0.5);
  box-shadow: 0px 3px 56px rgba(0, 0, 0, 0.2);
}

.hotspot-label.up .content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.hotspot-label.left .content {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.hotspot-label.down .content {
  display: flex;
  flex-direction: column-reverse;
  justify-content: center;
  align-items: center;
}

.hotspot-label.right .content {
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
  align-items: center;
}

.hotspot-label .content h3 {
  flex: 1;
  font-family: Open Sans;
  font-size: 30px;
  line-height: 117%;
  /* or 117% */

  text-align: center;
  color: #101010;
  white-space: pre;
  margin: 0;
}

.hotspot-label .content .pointer {
  flex: 1;
  margin: 0;
  z-index: 99;
  display: block;
}

.hotspot-label .content .pointer img {
  width: 45px;
  height: 45px;
}

.hotspot-label.up .content .pointer img,
.hotspot-label.up > .content > .pointer > svg {
  transform: rotate(0);
}

.hotspot-label.left .content .pointer img,
.hotspot-label.left > .content > .pointer > svg {
  transform: rotate(270deg);
}

.hotspot-label.down .content .pointer img,
.hotspot-label.down > .content > .pointer > svg {
  transform: rotate(180deg);
}

.hotspot-label.right .content .pointer img,
.hotspot-label.right > .content > .pointer > svg {
  transform: rotate(90deg);
}

/*.hotspot-label > .content > .pointer > svg {
}*/

.hotspot-label > .content > .pointer > svg > * {
  fill: rgb(0px 1px 3px rgba(255, 255, 255, 0.5));
  filter: none;
}

.hotspot-label > .dimension.up {
  width: 100px;
  height: 20px;
  border-top: 3px solid #ffffff;
  border-left: 3px solid #ffffff;
  border-right: 3px solid #ffffff;
}

.hotspot-label > .dimension.up > p {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -110%);
  font-family: Open Sans;
  font-size: 30px;
  line-height: 117%;
  margin: 0;
  color: #ffffff;
  text-align: center;
  white-space: pre;
  text-shadow: 0px 2px 3px rgba(0, 0, 0, 0.8);
}

.hotspot-label > .dimension.down {
  width: 100px;
  height: 20px;
  border-bottom: 3px solid #ffffff;
  border-left: 3px solid #ffffff;
  border-right: 3px solid #ffffff;
}

.hotspot-label > .dimension.down > p {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 110%);
  font-family: Open Sans;
  font-size: 30px;
  line-height: 117%;
  margin: 0;
  color: #ffffff;
  text-align: center;
  white-space: pre;
  text-shadow: 0px 2px 3px rgba(0, 0, 0, 0.8);
}

.hotspot-label > .dimension.left {
  height: 100px;
  width: 20px;
  border-bottom: 3px solid #ffffff;
  border-left: 3px solid #ffffff;
  border-top: 3px solid #ffffff;
}

.hotspot-label > .dimension.left > p {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(-110%, -50%);
  font-family: Open Sans;
  font-size: 30px;
  line-height: 117%;
  margin: 0;
  color: #ffffff;
  text-align: center;
  white-space: pre;
  text-shadow: 0px 2px 3px rgba(0, 0, 0, 0.8);
}

.hotspot-label > .dimension.right {
  height: 100px;
  width: 20px;
  border-bottom: 3px solid #ffffff;
  border-right: 3px solid #ffffff;
  border-top: 3px solid #ffffff;
}

.hotspot-label > .dimension.right > p {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translate(110%, -50%);
  font-family: Open Sans;
  font-size: 30px;
  line-height: 117%;
  margin: 0;
  color: #ffffff;
  text-align: center;
  white-space: pre;
  text-shadow: 0px 2px 3px rgba(0, 0, 0, 0.8);
}
