div {
  position: relative;
}

.explorer-container.pa * {
  cursor: url('../icon/poin-ask-pointer.svg'), auto;
}

@font-face {
  font-family: 'Merriweather Sans', sans-serif;
  src: url('//db.onlinewebfonts.com/t/bf9f5d50c1b928ff21436517a1a95ad9.eot');
  src: url('//db.onlinewebfonts.com/t/bf9f5d50c1b928ff21436517a1a95ad9.eot?#iefix')
      format('embedded-opentype'),
    url('//db.onlinewebfonts.com/t/bf9f5d50c1b928ff21436517a1a95ad9.woff2') format('woff2'),
    url('//db.onlinewebfonts.com/t/bf9f5d50c1b928ff21436517a1a95ad9.woff') format('woff'),
    url('//db.onlinewebfonts.com/t/bf9f5d50c1b928ff21436517a1a95ad9.ttf') format('truetype'),
    url('//db.onlinewebfonts.com/t/bf9f5d50c1b928ff21436517a1a95ad9.svg#Proxima Nova') format('svg');
}

@font-face {
  font-family: 'Merriweather Sans' T;
  src: url('//db.onlinewebfonts.com/t/0d54578761902bda6813941de15e8f84.eot');
  src: url('//db.onlinewebfonts.com/t/0d54578761902bda6813941de15e8f84.eot?#iefix')
      format('embedded-opentype'),
    url('//db.onlinewebfonts.com/t/0d54578761902bda6813941de15e8f84.woff2') format('woff2'),
    url('//db.onlinewebfonts.com/t/0d54578761902bda6813941de15e8f84.woff') format('woff'),
    url('//db.onlinewebfonts.com/t/0d54578761902bda6813941de15e8f84.ttf') format('truetype'),
    url('//db.onlinewebfonts.com/t/0d54578761902bda6813941de15e8f84.svg#Proxima Nova Thin')
      format('svg');
}

.topNameContainer {
  position: fixed;
  top: 4.63vh;
  left: 50%;
  transform: translate(-50%, 0);
  z-index: 88;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.topNameContainer > h1 {
  font-family: 'Mukta Vaani', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  line-height: 100%;
  /* identical to box height, or 150% */

  text-align: center;
  letter-spacing: 0.0025em;

  color: #ffffff;

  text-shadow: 0px 2px 3px rgba(0, 0, 0, 0.8);

  margin: 0 0 16px 0;
  padding: 0;
}

.topNameContainer > h2 {
  font-family: 'Mukta Vaani', sans-serif;
  font-size: 1vw;
  line-height: 110%;
  /* identical to box height */

  text-align: center;

  color: #ffffff;
  margin: 0;

  text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.7);
}

.feedback-container {
  position: fixed;
  bottom: 61px;
  left: 16px;
  background: #ffffff;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  z-index: 99;
  padding: 0 14px;
}

.feedback-container > svg {
  width: 24px;
  height: 24px;
  margin-right: 10px;
}

.feedback-container > svg > * {
  fill: #000004;
}

.feedback-container > p {
  font-family: 'Mukta Vaani', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  /* or 114% */

  display: flex;
  align-items: center;

  /* davys-grey-100 */

  color: #000004;
}

.video_new {
  width: 100%;
  height: auto;
  pointer-events: none;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.explorer-container > .group-container {
  position: fixed;
  bottom: 64px;
  left: 3.13vw;
  z-index: 99;
  transform: translate(0, -50%);
  cursor: pointer;
}

.explorer-container > .invitation-bg {
  z-index: 100;
}
