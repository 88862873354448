.modal-container {
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.5s ease;
}

.modal-container.active {
  opacity: 1;
  pointer-events: all;
  z-index: 99;
}

.modal-container > .background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(35, 35, 35, 0.5);
}

.hotspot-modal {
  z-index: 9;
}

.hotspot-modal > svg.new-label {
  position: absolute;
  transform: translate(-50%, -200%);
  width: 40px;
  height: auto;
  opacity: 1;
  cursor: auto;
  transition: all ease 0.3s;
}

.hotspot-modal > svg.new-label.hover {
  transform: translate(-50%, -250%);
}

.hotspot-modal > svg.new-label:hover {
  width: 34px;
}

.hotspot-modal > div.new-label {
  position: absolute;
  transform: translate(-50%, -275%) skew(-15deg);
  height: auto;
  opacity: 1;
  cursor: auto;
  font-family: Myriad Pro;
  font-style: italic;
  font-weight: bold;
  font-size: 14px;
  line-height: 15px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.0125em;
  text-transform: uppercase;
  color: #ffffff;
  background: #e84855;
  box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.6);
  padding: 0 5px 0 1px;
}

.hotspot-modal > svg {
  position: absolute;
  transform: translate(-50%, -50%);
  width: 40px;
  height: auto;
  opacity: 1;
  transition: opacity 0.3s ease;
  filter: drop-shadow(0px 2px 1px rgba(0, 0, 0, 0.7));
  transition: width 0.3s ease;
  cursor: pointer;
}

.hotspot-modal > svg:hover,
.hotspot-modal > svg.hovered {
  width: 60px;
}

.hotspot-modal > svg * {
  filter: none;
}

.hotspot-modal > .hotspot {
  position: absolute;
  transform: translate(-50%, -50%);
  width: auto;
  height: 5.9vh;
  opacity: 0.9;
}

.hotspot-modal > .hotspot-hover {
  position: absolute;
  transform: translate(-50%, -50%);
  width: auto;
  height: 8vh;
  opacity: 0;
  z-index: 1;
  transition: opacity 0.5s ease;
  cursor: pointer;
}

.hotspot-modal > .hotspot-hover.active {
  opacity: 1;
}

.hotspot-modal:hover > .hotspot-hover {
  opacity: 1;
}

.hotspot-modal > .video-thumbnail {
  position: absolute;
  transform: translate(-50%, -50%);
  height: 12vh;
  width: auto;
  pointer-events: none;
}

.hotspot-modal > .tooltip {
  position: absolute;
  transform: translate(-50%, -50%);
  opacity: 0;
  background: #ffffff;
  font-family: 'Mukta Vaani', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 100%;
  color: #000004;
  overflow: hidden;
  padding: 8px;
  pointer-events: none;
  white-space: nowrap;
  transition: opacity 0.3s ease, visibility 0.3s ease,
    transform 0.3s cubic-bezier(0.71, 1.7, 0.77, 1.24);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.hotspot-modal:hover > .tooltip,
.hotspot-modal > .tooltip.hovered {
  opacity: 0.9;
  transform: translate(35px, -50%);
}

.hotspot-modal > .tooltip > h6 {
  font-family: 'Mukta Vaani', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 100%;
  letter-spacing: 0.0015em;
  color: #000004;
  margin: 0 0 8px 0;
}

.hotspot-modal > .tooltip > p {
  font-family: 'Mukta Vaani', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 100%;
  letter-spacing: 0.0015em;
  color: #464646;
  margin: 0;
  white-space: pre;
}

.hotspot-modal > .hotspot-pulse {
  position: absolute;
  opacity: 0;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background: #ffffff;
  transform: translate(-50%, -50%);
  animation: pulsation 1.5s normal ease-out;
}

@keyframes pulsation {
  0% {
    transform: translate(-50%, -50%) scale(0.25, 0.25);
    opacity: 1;
  }
  100% {
    transform: translate(-50%, -50%) scale(1, 1);
    opacity: 0;
  }
}
