.modal-link {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.6);
  border-radius: 5px;
  overflow: hidden;
}

.mobile-modal-link > .scroll {
  position: absolute;
  bottom: 0;
  width: 100%;
  z-index: 1;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.mobile-modal-link > .scroll.active {
  opacity: 1;
}

.mobile-modal-link > .scroll > .scroll-arrow {
  position: absolute;
  left: 50%;
  bottom: 24px;
  width: 20px;
  height: 20px;
  border-left: 2px solid rgb(var(--main-color));
  border-bottom: 2px solid rgb(var(--main-color));
  animation: scroll-bounce 1.5s infinite;
}

@keyframes scroll-bounce {
  0% {
    transform: translate(-50%) rotate(-45deg) translate(0, 0);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: translate(-50%) rotate(-45deg) translate(-10px, 10px);
    opacity: 0;
  }
}

.modal-link > .link-title {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.modal-link > .link-title > h1 {
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 27px;
  color: #ffffff;
  margin: 0;
  padding: 0;
  padding-left: 16px;
  background: rgb(var(--main-color));
  width: 100%;
  height: 48px;
  display: flex;
  align-items: center;
}

.modal-link > .link-title > .link-close {
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background: rgb(var(--main-dark));
}

.modal-link > .link-title > .link-close > svg {
  width: 24px;
  height: 24px;
}

.modal-link > .link-title > .link-close > svg > * {
  fill: #ffffff;
}

.modal-link > .content-link {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background: #ffffff;
}

.modal-link > .content-link > .link-img {
  max-width: 40vw;
  width: auto;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 16px 8px 16px 16px;
}

.modal-link > .content-link > .link-img > img {
  /*position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);*/
  height: 50vh;
  width: auto;
}

.modal-link > .content-link > .link-text {
  width: 18vw;
  height: 50vh;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  margin: 16px 16px 16px 8px;
  overflow: hidden;
}

.modal-link > .content-link > .link-text > h1 {
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 0.95vw;
  line-height: 142%;
  letter-spacing: 0.005em;
  color: #000004;
  margin: 0;
  padding: 0;
  overflow: auto;
  visibility: hidden;
}

.modal-link > .content-link > .link-text > p {
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 0.95vw;
  line-height: 142%;
  letter-spacing: 0.005em;
  color: #000004;
  margin: 0 0 1.4vw 0;
  padding: 0;
  max-height: 100%;
  overflow: auto;
  white-space: pre-wrap;
}

.modal-link > .content-link > .link-text > div {
  border: 2px solid rgb(var(--main-color));
  box-sizing: border-box;
  border-radius: 5px;
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 0.9vw;
  line-height: 123%;
  text-align: center;
  letter-spacing: 0.0125em;
  text-transform: uppercase;
  color: rgb(var(--main-color));
  padding: 0.7vw 0.9vw;
  cursor: pointer;
}

.mobile-modal-link {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.mobile-modal-link > h1 {
  background: rgb(var(--main-color));

  font-family: 'Mukta Vaani', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 100%;
  color: #ffffff;

  margin: 0;

  padding-left: 16px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.mobile-modal-link > h1 > svg {
  position: absolute;
  right: 0;
  top: 0;
  width: 56px;
  height: 40px;
  padding: 8px 16px;
  background: rgb(var(--main-dark));
}

.mobile-modal-link > h1 > svg > * {
  fill: #ffffff;
}

.mobile-modal-link > .mobile-content-link {
  height: calc(100% - 36px);
  padding: 16px;
  background: #ffffff;
  overflow: auto;
}

.mobile-modal-link > .mobile-content-link > .mobile-modal-img {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.mobile-modal-link > .mobile-content-link > .mobile-modal-img > img {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: auto;
  height: 100%;
}

.mobile-modal-link > .mobile-content-link > .mobile-text {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.mobile-modal-link > .mobile-content-link > .mobile-text > h1 {
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: 600;
  color: #000004;
  padding: 0;
  font-size: 3vw;
  line-height: 100%;
  margin: 0 0 8px;
  visibility: hidden;
}

.mobile-modal-link > .mobile-content-link > .mobile-text > p {
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: 400;
  color: #000004;
  padding: 0;
  font-size: 2vw;
  line-height: 100%;
  margin: 0 0 8px;
}

.mobile-modal-link > .mobile-content-link > .mobile-text > div {
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: 600;
  color: rgb(var(--main-color));
  padding: 0;
  font-size: 2vw;
  line-height: 200%;
  letter-spacing: 0.0125em;
  text-transform: uppercase;
  border: 2px solid rgb(var(--main-color));
  box-sizing: border-box;
  border-radius: 5px;
  width: 100%;
  text-align: center;
}
