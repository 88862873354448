.modal-video {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 71.35vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.6);
  border-radius: 5px;
  padding: 16px;
  transition: height 0.3s ease-in-out;
}

.modal-video > iframe {
  width: 100%;
  height: 100%;
}

.modal-video > video {
  width: 100%;
  height: auto;
}

.modal-video > p {
  width: 100%;
  font-family: 'Mukta Vaani', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 100%;
  color: #000004;
  margin: 16px 0 0 0;
  padding: 0;
}

.mobile-modal-video {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #ffffff;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.6);
  border-radius: 5px;
  overflow: auto;
}

.mobile-modal-video > iframe {
  width: 100%;
  height: 100%;
}

.mobile-modal-video > p {
  width: 350px;
  font-family: 'Mukta Vaani';
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 100%;
  display: flex;
  align-items: center;
  color: #000004;
  position: absolute;
  bottom: 16px;
  left: 50%;
  transform: translate(-50%);
  margin: 0;
}

.mobile-modal-video > video {
  width: 350px;
  height: auto;
  position: absolute;
  top: 16px;
  left: 50%;
  transform: translate(-50%);
}

.mobile-modal-video > svg {
  width: 24px;
  height: 24px;
  position: absolute;
  z-index: 1;
  top: 16px;
  right: 16px;
}

.mobile-modal-video > svg > * {
  fill: #101010;
}
